import * as types from '../actionType';
const RESPONSE_SUCCESS = '1';

const initState = {
  isLogin: false || localStorage.getItem('isLogin') === 'true',
  adminName: localStorage.getItem('adminName'),
  access_token: localStorage.getItem('access_token'),
  isRemberMe: false || localStorage.getItem('isRemberMe') === 'true',
  authority: false || localStorage.getItem('authority'),
};

function LoginState(state = initState, action) {
  switch (action.type) {
    case types.ADMIN_LOGIN_SUCCESS: {
      let {
        error,
        access_token,
        username,
        authority,
        refresh_token,
      } = action.result;
      let newObj = Object.assign({}, state);
      if (error.toString() === RESPONSE_SUCCESS) {
        newObj.isLogin = true;
        newObj.access_token = access_token;
        newObj.adminName = username;
        newObj.authority = authority;
        newObj.refresh_token = refresh_token;

        localStorage.setItem('isLogin', newObj.isLogin);
        localStorage.setItem('adminName', newObj.adminName);
        localStorage.setItem('access_token', newObj.access_token);
        localStorage.setItem('authority', newObj.authority);
        localStorage.setItem('refresh_token', newObj.refresh_token);

        if (newObj.isRemberMe) {
          localStorage.setItem('rememberAdminName', action.username);
        } else {
          localStorage.removeItem('rememberAdminName');
        }
      } else {
        console.log(action.result);
      }
      return newObj;
    }
    case types.LOGOUT_SUCCESS: {
      let newObj = Object.assign({}, state);
      newObj.isLogin = false;
      newObj.adminName = '';
      newObj.access_token = '';
      newObj.authority = false;
      localStorage.removeItem('isLogin');
      localStorage.removeItem('adminName');
      localStorage.removeItem('access_token');
      localStorage.removeItem('authority');
      localStorage.removeItem('refresh_token');
      return newObj;
    }
    case types.SWITCH_REMEMBER_ME: {
      let newObj = Object.assign({}, state);
      newObj.isRemberMe = !newObj.isRemberMe;
      localStorage.setItem('isRemberMe', newObj.isRemberMe);
      if (!newObj.isRemberMe) {
        localStorage.removeItem('rememberAdminName');
      }
      return newObj;
    }
    default:
      return state;
  }
}

export default LoginState;
