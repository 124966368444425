import React, { useState } from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  Switch,
  FormControlLabel,
  IconButton,
  Button,
  Card,
  CardContent,
  CardActions,
} from '@material-ui/core';
import Map from '../../data/Map';
import HomeWorkRoundedIcon from '@material-ui/icons/HomeWorkRounded';
import LibraryBooksRoundedIcon from '@material-ui/icons/LibraryBooksRounded';
import PictureAsPdfRoundedIcon from '@material-ui/icons/PictureAsPdfRounded';
import VideocamRoundedIcon from '@material-ui/icons/VideocamRounded';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DoneOutlineRoundedIcon from '@material-ui/icons/DoneOutlineRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import _ from 'lodash';
import Swal from 'sweetalert2';
import ResourceName from './ResourceName';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ResourceAssignment from './ResourceAssignment';

const ChapterResources = (props) => {
  const {
    resources,
    deleteResource,
    changeResource,
    changeResourceOrdering,
  } = props;
  const [isProcessing, setIsProcessing] = useState(false);
  const [editResource, setEditResource] = useState();

  const handleDeleteResource = (id) => {
    Swal.fire({
      title: '確定要刪除嗎？',
      showCancelButton: true,
      confirmButtonText: '刪除',
      cancelButtonText: '取消',
    }).then((result) => {
      if (result.isConfirmed) {
        setIsProcessing(true);

        deleteResource(id).then(() => {
          setIsProcessing(false);
        });
      }
    });
  };

  const handleChangeResource = (resource) => {
    setIsProcessing(true);

    // 此函式僅使用在 開放試看和改名稱呼叫使用，因此作業類型呼叫時，需把作業資訊一併帶入，其它類型則維持原本內容
    // 作業類型的內容修改，有提供 handleChangeAssignment 使用
    const newResource =
      resource.MaterialType === Map.MaterialTypes.assignment
        ? {
            HangoutChapterMaterialId: resource.HangoutChapterMaterialId,
            Name: resource.Name,
            IsFree: resource.IsFree,
            Description: resource.Description,
            SubmissionType: resource.SubmissionType,
            SubmissionCount:
              parseInt(resource.SubmissionCount, 10) <= 0
                ? null
                : resource.SubmissionCount,
          }
        : {
            HangoutChapterMaterialId: resource.HangoutChapterMaterialId,
            Name: resource.Name,
            IsFree: resource.IsFree,
          };

    return changeResource(newResource).then(() => {
      setIsProcessing(false);
    });
  };

  const handleChangeAssignment = () => {
    if (!!editResource) {
      setIsProcessing(true);

      return changeResource({
        HangoutChapterMaterialId: editResource.id,
        Name: editResource.name,
        IsFree: editResource.isFree,
        Description: _.escape(editResource.description),
        SubmissionType: editResource.submissionType,
        SubmissionCount:
          parseInt(editResource.submissionCount, 10) <= 0
            ? null
            : editResource.submissionCount,
      }).then(() => {
        setIsProcessing(false);
        setEditResource(undefined);
      });
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.source.index === result.destination.index) {
      return;
    }

    setIsProcessing(true);

    const resourceId = resources[result.source.index].HangoutChapterMaterialId;
    const newOrder = parseInt(result.destination.index, 10) + 1;

    changeResourceOrdering({
      HangoutChapterMaterialId: resourceId,
      Order: newOrder,
    }).then(() => {
      setIsProcessing(false);
    });
  };

  const handleSetEditResource = (resource) => {
    setEditResource({
      id: resource.HangoutChapterMaterialId,
      name: resource.Name,
      description: resource.Description,
      submissionType: resource.SubmissionType,
      submissionCount: resource.SubmissionCount,
      isFree: resource.IsFree,
    });
  };

  const handleCancelEditResource = () => {
    setEditResource(undefined);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <List>
        <Droppable droppableId="materialDropableId">
          {(droppableProvided) => (
            <div
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}>
              {resources &&
                _.map(resources, (resource, index) => (
                  <Draggable
                    key={resource.HangoutChapterMaterialId}
                    draggableId={String(resource.HangoutChapterMaterialId)}
                    index={index}
                    isDragDisabled={isProcessing || !!editResource}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.dragHandleProps}
                        {...provided.draggableProps}>
                        <ListItem disabled={isProcessing}>
                          <ListItemIcon>
                            {Map.MaterialTypeNumbers[resource.MaterialType]
                              .type === 'video' && <VideocamRoundedIcon />}
                            {Map.MaterialTypeNumbers[resource.MaterialType]
                              .type === 'questionBank' && (
                              <LibraryBooksRoundedIcon />
                            )}
                            {Map.MaterialTypeNumbers[resource.MaterialType]
                              .type === 'homeWork' && <HomeWorkRoundedIcon />}
                            {Map.MaterialTypeNumbers[resource.MaterialType]
                              .type === 'pdf' && <PictureAsPdfRoundedIcon />}
                            {Map.MaterialTypeNumbers[resource.MaterialType]
                              .type === 'assignment' && (
                              <AssignmentRoundedIcon />
                            )}
                          </ListItemIcon>

                          {(!editResource ||
                            editResource.id !==
                              resource.HangoutChapterMaterialId) && (
                            <>
                              <ResourceName
                                resource={resource}
                                changeResource={handleChangeResource}
                              />

                              <ListItemSecondaryAction>
                                {resource.MaterialType !==
                                  Map.MaterialTypes.assignment && (
                                  <FormControlLabel
                                    disabled={isProcessing}
                                    control={
                                      <Switch
                                        color="primary"
                                        onChange={(e) => {
                                          handleChangeResource({
                                            ...resource,
                                            IsFree: e.target.checked,
                                          });
                                        }}
                                        checked={resource.IsFree}
                                      />
                                    }
                                    label="開放試看"
                                  />
                                )}

                                {resource.MaterialType ===
                                  Map.MaterialTypes.assignment && (
                                  <IconButton
                                    disabled={isProcessing}
                                    onClick={() => {
                                      handleSetEditResource(resource);
                                    }}>
                                    <EditRoundedIcon
                                      color={
                                        isProcessing ? 'disabled' : 'primary'
                                      }
                                    />
                                  </IconButton>
                                )}

                                <IconButton
                                  edge="end"
                                  aria-label="delete"
                                  onClick={() => {
                                    handleDeleteResource(
                                      resource.HangoutChapterMaterialId,
                                    );
                                  }}
                                  disabled={isProcessing}>
                                  <DeleteForeverRoundedIcon
                                    color={
                                      isProcessing ? 'disabled' : 'secondary'
                                    }
                                  />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </>
                          )}

                          {editResource &&
                            editResource.id ===
                              resource.HangoutChapterMaterialId && (
                              <Card variant="outlined">
                                <CardContent>
                                  <ResourceAssignment
                                    resource={editResource}
                                    setResource={setEditResource}
                                  />
                                </CardContent>
                                <CardActions>
                                  <Button
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    startIcon={<DoneOutlineRoundedIcon />}
                                    onClick={handleChangeAssignment}>
                                    更改
                                  </Button>
                                  <Button
                                    size="small"
                                    color="default"
                                    variant="outlined"
                                    startIcon={<CloseRoundedIcon />}
                                    onClick={handleCancelEditResource}>
                                    取消
                                  </Button>
                                </CardActions>
                              </Card>
                            )}
                        </ListItem>
                      </div>
                    )}
                  </Draggable>
                ))}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </List>
    </DragDropContext>
  );
};

export default ChapterResources;
