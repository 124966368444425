import {
  Button,
  ButtonGroup,
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import React from 'react';
import Editor from '../Editor';
import { map, unescape } from 'lodash';
import Map from '../../data/Map';

const ResourceAssignment = (props) => {
  const { resource, setResource } = props;

  return (
    <div className="add--resource__inputs">
      <TextField
        label="作業名稱"
        fullWidth
        value={resource.name}
        onChange={(e) => {
          setResource({ ...resource, name: e.target.value });
        }}
      />

      <div>內容說明</div>

      <Editor
        content={unescape(resource.description)}
        setContent={(newContent) => {
          setResource({
            ...resource,
            description: newContent,
          });
        }}
        imageS3Position={{
          bucket: 'questionbank-resource',
          dir: 'HangoutPic/material/Assignment',
          acl: 'public-read',
        }}
        editorProps={{
          height: 200,
          image_dimensions: false,
        }}
      />

      <div>繳交設定</div>

      <Grid
        container
        direction="row"
        justifycontent="space-around"
        alignItems="center"
        spacing={3}>
        <Grid item xs={6}>
          <div>上傳檔案</div>

          <ButtonGroup>
            {map(Map.SubmissionTypes, (type, typeId) => {
              const isSelected =
                parseInt(resource.submissionType, 10) === parseInt(typeId, 10);

              return (
                <Button
                  key={typeId}
                  onClick={() => {
                    setResource({
                      ...resource,
                      submissionType: typeId,
                    });
                  }}
                  color={isSelected ? 'primary' : 'default'}
                  variant={isSelected ? 'contained' : 'outlined'}>
                  {type.name}
                </Button>
              );
            })}
          </ButtonGroup>
        </Grid>

        <Grid item xs={6}>
          <div>上傳次數</div>

          <TextField
            label="無限制或輸入上限次數"
            color="secondary"
            value={resource.submissionCount || ''}
            fullWidth
            onChange={(e) => {
              if (/^[0-9]*$/.test(e.target.value) === true) {
                setResource({
                  ...resource,
                  submissionCount: e.target.value,
                });
              }
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ResourceAssignment;
