import React, { useEffect, useState, useMemo } from 'react';
import BreakCrumbs from '../../components/BreakCrumbs';
import { useHistory, useParams } from 'react-router-dom';
import {
  Box,
  Grid,
  Button,
  Paper,
  Typography,
  Divider,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Chip,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { map, trim } from 'lodash';
import Swal from 'sweetalert2';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import KeyboardReturnOutlinedIcon from '@material-ui/icons/KeyboardReturnOutlined';
import { alertError } from '../../Util/Util';
import bonusProductAPI from '../../middleware/bonusProductAPI';
import {
  BonusProductExpriyDayText,
  BonusProductStatus,
  BonusProductStatusText,
  BonusProductType,
  BonusProductTypeText,
} from '../../data/common';
import Editor from '../../components/Editor';
import { AWS_BONUS_PRODUCT_POSITION } from '../../data/aws';
import { DatePicker } from '@material-ui/pickers';
import CoverAndImages from '../../components/BonusProduct/CoverAndImages';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { addFlashMessage } from '../../store/action';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Alert from '@material-ui/lab/Alert';

const BonusProduct = (props) => {
  const { match } = props;
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [form, setForm] = useState({
    name: '',
    status: BonusProductStatus.ON_SALE,
    onSaleDate: null,
    offSaleDate: null,
    type: BonusProductType.EXCHANGE,
    bonusPoint: '',
    cash: '',
    expriyDateDay: '',
    quantity: '',
    description: '',
    barcode: '',
    isPromo: false,
  });
  const [mainImage, setMainImage] = useState();
  const [subImages, setSubImages] = useState([]);
  const [deleteImageIds, setDeleteImageIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const isCreate = useMemo(() => {
    return !id;
  }, [id]);
  const isRedeem = id && parseInt(form.redeemQuantity, 10) > 0;

  useEffect(() => {
    if (id) {
      setIsLoading(true);

      bonusProductAPI
        .get(id)
        .then(
          (res) => {
            setForm(res);
            setMainImage(res.mainImage);
            setSubImages(res.subImage);
          },
          (error) => {
            alertError(error);
          },
        )
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [id]);

  const handleChangeForm = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    if (trim(form.name) === '') {
      Swal.fire({
        icon: 'info',
        title: '請填寫名稱',
      });

      return false;
    }

    if (trim(form.status) === '') {
      Swal.fire({
        icon: 'info',
        title: '請設定狀態',
      });

      return false;
    }

    if (trim(form.type) === '') {
      Swal.fire({
        icon: 'info',
        title: '請選擇優惠券類別',
      });

      return false;
    }

    if (trim(form.bonusPoint) === '' || form.bonusPoint <= 0) {
      Swal.fire({
        icon: 'info',
        title: '請設定紅利點數，並大於0',
      });

      return false;
    }

    if (
      form.type === BonusProductType.DISCOUNT &&
      (trim(form.cash) === '' || form.cash <= 0)
    ) {
      Swal.fire({
        icon: 'info',
        title: `${BonusProductTypeText[BonusProductType.DISCOUNT]}請設定現金`,
      });

      return false;
    }

    if (trim(form.expriyDateDay) === '') {
      Swal.fire({
        icon: 'info',
        title: '請選擇使用期限',
      });

      return false;
    }

    if (trim(form.quantity) === '') {
      Swal.fire({
        icon: 'info',
        title: '請設定數量',
      });

      return false;
    }

    if (!mainImage) {
      Swal.fire({
        icon: 'info',
        title: '請設定封面',
      });

      return false;
    }

    const onSaleDate = form.onSaleDate
      ? moment(form.onSaleDate).format('yyyy-MM-DD') + ' 00:00:00'
      : undefined;

    const offSaleDate = form.offSaleDate
      ? moment(form.offSaleDate).format('yyyy-MM-DD') + ' 23:59:59'
      : undefined;

    const uploadImages = subImages.filter((subImage) => {
      return subImage.isUpload && subImage.isDelete !== true;
    });

    const deleteImageIds = subImages
      .filter((subImage) => {
        return subImage.isDelete && subImage.isUpload !== true;
      })
      .map((subImage) => subImage.id);

    setIsLoading(true);

    const api = isCreate
      ? bonusProductAPI.create(
          form.name,
          form.type,
          form.status,
          onSaleDate,
          offSaleDate,
          form.description,
          form.expriyDateDay,
          form.bonusPoint,
          form.cash,
          form.quantity,
          mainImage.file,
          uploadImages.map((subImage) => subImage.file),
          form.barcode,
          form.isPromo,
        )
      : bonusProductAPI.update(
          id,
          form.name,
          form.type,
          form.status,
          onSaleDate,
          offSaleDate,
          form.description,
          form.expriyDateDay,
          form.bonusPoint,
          form.cash,
          form.quantity,
          mainImage.isUpload ? mainImage.file : undefined,
          mainImage.isUpload !== true ? mainImage.id : undefined,
          uploadImages.map((subImage) => subImage.file),
          Array.isArray(deleteImageIds) && deleteImageIds.length > 0
            ? deleteImageIds.join(',')
            : undefined,
          form.barcode,
          form.isPromo,
        );

    api.then(
      (res) => {
        dispatch(addFlashMessage(isCreate ? '建立成功' : '修改成功'));
        history.push('/BonusProducts/BonusProductList');
      },
      (error) => {
        alertError(error);
        setIsLoading(false);
      },
    );
  };

  const handleSetMainImage = (image) => {
    setMainImage(image);
  };

  const handleSetSubImages = (images) => {
    setSubImages(images);
  };

  const handleSetDeleteImageIds = (ids) => {
    setDeleteImageIds(ids);
  };

  return (
    <div className="popular pagefadeIn">
      <BreakCrumbs path={match.url}></BreakCrumbs>

      <Box component={Paper} square elevation={2} p={1} mt={3} mb={3}>
        <Typography variant="h5" color="textPrimary" gutterBottom>
          {isCreate ? '建立紅利商品' : '修改紅利商品'}

          {isRedeem && (
            <Chip
              color="secondary"
              icon={<LockOutlinedIcon />}
              label="商品已兌換"
              size="small"
              style={{ marginLeft: '20px' }}
            />
          )}
        </Typography>

        <Divider light />

        <Box p={3}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-start"
            spacing={2}>
            <Grid item xs={4}>
              <TextField
                required
                fullWidth={true}
                label="商品名稱"
                value={form.name || ''}
                onChange={(e) => {
                  handleChangeForm('name', e.target.value);
                }}
                disabled={isLoading || isRedeem}
              />
            </Grid>

            <Grid item xs={4}>
              <FormControl fullWidth required disabled={isLoading}>
                <InputLabel>狀態</InputLabel>
                <Select
                  value={form.status}
                  onChange={(e) => {
                    handleChangeForm('status', parseInt(e.target.value, 10));
                  }}
                  label="狀態">
                  <MenuItem value={BonusProductStatus.ON_SALE}>
                    {BonusProductStatusText[BonusProductStatus.ON_SALE]}
                  </MenuItem>

                  <MenuItem value={BonusProductStatus.OFF_SALE}>
                    {BonusProductStatusText[BonusProductStatus.OFF_SALE]}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <FormControl fullWidth disabled={isLoading}>
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      color="secondary"
                      checked={form.isPromo}
                      onChange={(e) => {
                        handleChangeForm('isPromo', e.target.checked);
                      }}
                    />
                  }
                  label="會員註冊禮"
                  labelPlacement="bottom"
                />
              </FormControl>
              {form.isPromo && (
                <Alert severity="error">
                  當勾選時，若為下線狀態，依然會呈現在會員註冊禮。
                </Alert>
              )}
            </Grid>

            <Grid item xs={4}>
              <FormControl fullWidth required disabled={isLoading || isRedeem}>
                <InputLabel>優惠券類別</InputLabel>
                <Select
                  value={form.type || ''}
                  name="type"
                  onChange={(e) => {
                    handleChangeForm('type', parseInt(e.target.value, 10));
                  }}
                  label="類別">
                  {map(BonusProductTypeText, (text, type) => {
                    return (
                      <MenuItem value={type} key={type}>
                        {text}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <TextField
                fullWidth={true}
                label="紅利點數"
                required
                value={form.bonusPoint || ''}
                type="number"
                onChange={(e) => {
                  handleChangeForm('bonusPoint', e.target.value);
                }}
                disabled={isLoading || isRedeem}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                fullWidth={true}
                label="優惠金額"
                value={form.cash || ''}
                type="number"
                onChange={(e) => {
                  handleChangeForm('cash', e.target.value);
                }}
                disabled={
                  isLoading ||
                  (form && form.type === BonusProductType.EXCHANGE) ||
                  isRedeem
                }
              />
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth required disabled={isLoading || isRedeem}>
                <InputLabel>使用期限</InputLabel>
                <Select
                  value={form.expriyDateDay || ''}
                  onChange={(e) => {
                    handleChangeForm('expriyDateDay', e.target.value);
                  }}
                  label="使用期限">
                  {map(BonusProductExpriyDayText, (text, day) => {
                    return (
                      <MenuItem value={day} key={day}>
                        {text}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <TextField
                fullWidth
                label="產品數量"
                required
                value={form.quantity || ''}
                type="number"
                onChange={(e) => {
                  handleChangeForm('quantity', e.target.value);
                }}
                disabled={isLoading}
              />
            </Grid>

            <Grid item xs={6}>
              <DatePicker
                autoOk
                label="上架時間"
                clearable
                fullWidth
                value={form.onSaleDate}
                onChange={(date) => {
                  handleChangeForm('onSaleDate', date);
                }}
                format="yyyy-MM-dd"
              />
            </Grid>

            <Grid item xs={6}>
              <DatePicker
                autoOk
                label="下架時間"
                clearable
                fullWidth
                value={form.offSaleDate}
                onChange={(date) => {
                  handleChangeForm('offSaleDate', date);
                }}
                format="yyyy-MM-dd"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Barcode"
                value={form.barcode || ''}
                onChange={(e) => {
                  handleChangeForm('barcode', e.target.value);
                }}
                disabled={isLoading}
              />
            </Grid>

            <Grid item xs={12}>
              <CoverAndImages
                mainImage={mainImage}
                subImages={subImages}
                deleteIds={deleteImageIds}
                setMainImage={handleSetMainImage}
                setSubImages={handleSetSubImages}
                setDeleteIds={handleSetDeleteImageIds}
              />
            </Grid>

            <Grid item xs={12}>
              <Editor
                content={decodeURIComponent(form.description)}
                setContent={(newContent) => {
                  handleChangeForm(
                    'description',
                    encodeURIComponent(newContent),
                  );
                }}
                imageS3Position={{
                  bucket: AWS_BONUS_PRODUCT_POSITION.description.bucket,
                  dir: AWS_BONUS_PRODUCT_POSITION.description.dir,
                  acl: AWS_BONUS_PRODUCT_POSITION.description.acl,
                }}
                editorProps={{
                  height: '350',
                  placeholder: '商品說明',
                }}
                disabled={isLoading}
              />
            </Grid>
          </Grid>
        </Box>

        <Divider light />

        <Box p={1} mt={2} mb={2} textAlign="right">
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{ marginRight: '24px' }}
            startIcon={<SaveOutlinedIcon />}
            disabled={isLoading}
            onClick={handleSubmit}>
            {isCreate ? '建立' : '修改'}
          </Button>
          <Button
            variant="contained"
            color="default"
            size="large"
            startIcon={<KeyboardReturnOutlinedIcon />}
            disabled={isLoading}
            onClick={() => {
              history.push('/BonusProducts/BonusProductList');
            }}>
            回列表
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default BonusProduct;
