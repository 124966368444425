import instance from './API';
import { trim } from 'lodash';

const bonusProductAPI = {
  get: (id) => {
    return instance.get(`/BonusProduct/${id}`);
  },

  getList: (status, keyword, isPromo, isReferralCode, count, page) => {
    return instance.get(`/BonusProduct`, {
      params: {
        status: status,
        keyword: keyword,
        isPromo: isPromo,
        isReferralCode: isReferralCode,
        page: page,
        count: count,
      },
    });
  },

  create: (
    name,
    type,
    status,
    onSaleDate,
    offSaleDate,
    description,
    expriyDateDay,
    bonusPoint,
    cash,
    quantity,
    mainImage,
    subImages,
    barcode,
    isPromo,
  ) => {
    const formData = new FormData();

    formData.append('Name', name);
    formData.append('Type', type);
    formData.append('Status', status);
    formData.append('Description', description);
    formData.append('ExpriyDateDay', expriyDateDay);
    formData.append('BonusPoint', bonusPoint);
    formData.append('Quantity', quantity);
    formData.append('MainImage', mainImage);
    formData.append('IsPromo', isPromo);

    if (barcode && barcode !== null) {
      formData.append('Barcode', barcode);
    }

    if (onSaleDate) {
      formData.append('OnSaleDate', onSaleDate);
    }

    if (offSaleDate) {
      formData.append('OffSaleDate', offSaleDate);
    }

    if (cash) {
      formData.append('Cash', cash);
    }

    if (Array.isArray(subImages)) {
      subImages.map((subImage) => {
        formData.append('SubImage', subImage);
      });
    }

    return instance.post('/BonusProduct', formData);
  },

  update: (
    id,
    name,
    type,
    status,
    onSaleDate,
    offSaleDate,
    description,
    expriyDateDay,
    bonusPoint,
    cash,
    quantity,
    mainImage,
    mainImageId,
    subImages,
    deleteImageIds,
    barcode,
    isPromo,
  ) => {
    const formData = new FormData();

    formData.append('Name', name);
    formData.append('Type', type);
    formData.append('Status', status);
    formData.append('Description', description);
    formData.append('ExpriyDateDay', expriyDateDay);
    formData.append('BonusPoint', bonusPoint);
    formData.append('Quantity', quantity);
    formData.append('IsPromo', isPromo);

    if (barcode && barcode !== null) {
      formData.append('Barcode', barcode);
    }

    if (mainImage) {
      formData.append('MainImage', mainImage);
    }

    if (mainImageId) {
      formData.append('MainImageId', mainImageId);
    }

    if (onSaleDate) {
      formData.append('OnSaleDate', onSaleDate);
    }

    if (offSaleDate) {
      formData.append('OffSaleDate', offSaleDate);
    }

    if (cash) {
      formData.append('Cash', cash);
    }

    if (Array.isArray(subImages)) {
      subImages.map((subImage) => {
        formData.append('SubImage', subImage);
      });
    }

    if (deleteImageIds) {
      formData.append('DeleteImageId', deleteImageIds);
    }

    return instance.put(`/BonusProduct/${id}`, formData);
  },

  setStatus: (id, status) => {
    return instance.patch(`/BonusProduct/${id}`, undefined, {
      params: {
        status: status,
      },
    });
  },

  delete: (id) => {
    return instance.patch(`/BonusProduct/${id}`, undefined, {
      params: {
        status: 2,
      },
    });
  },

  getRecommendList: () => {
    return instance.get('/BonusProduct/Recommend');
  },

  addRecommend: (id, sort) => {
    return instance.post(`/SortBonusProduct/${id}`, undefined, {
      params: {
        order: sort,
      },
    });
  },

  setRecommendSort: (id, sort) => {
    return instance.post(`/SortBonusProduct/${id}`, undefined, {
      params: {
        order: sort,
      },
    });
  },

  removeRecommend: (id) => {
    return instance.delete(`/SortBonusProduct/${id}`);
  },

  getUserBonusProductList: (page, count, searchs) => {
    return instance.get('/UserBonusProduct', {
      params: {
        page: page,
        count: count,
        startat:
          searchs && trim(searchs.startUseDate) !== ''
            ? searchs.startUseDate
            : undefined,
        endat:
          searchs && trim(searchs.endUseDate) !== ''
            ? searchs.endUseDate
            : undefined,
        type:
          searchs && trim(searchs.bonusProductType) !== ''
            ? searchs.bonusProductType
            : undefined,
        status:
          searchs && trim(searchs.userBonusProductStatus)
            ? searchs.userBonusProductStatus
            : undefined,
        keyword:
          searchs && trim(searchs.keyword) !== '' ? searchs.keyword : undefined,
        isPromo: searchs && searchs.isPromo === true ? true : undefined,
        isReferralCode:
          searchs && searchs.isReferralCode === true ? true : undefined,
      },
    });
  },

  getReferralCode: (bonusProductId) => {
    return instance.get(`/BonusProduct/${bonusProductId}/Code`);
  },

  createReferralCode: (bonusProductId, data) => {
    return instance.post(
      `/BonusProduct/${bonusProductId}/Code?code=${data.code}&startDate=${data.startDate}&endDate=${data.endDate}`,
    );
  },

  updateReferralCode: (bonusProductId, codeId, data) => {
    return instance.put(
      `/BonusProduct/${bonusProductId}/Code/${codeId}?code=${data.code}&startDate=${data.startDate}&endDate=${data.endDate}`,
    );
  },

  deleteReferralCode: (bonusProductId, codeId) => {
    return instance.delete(`/BonusProduct/${bonusProductId}/Code/${codeId}`);
  },
};

export default bonusProductAPI;
