import React, { useState, useEffect } from 'react';
import BreakCrumbs from '../../components/BreakCrumbs';
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Divider,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import TableLoading from '../../components/TableLoading';
import { alertError } from '../../Util/Util';
import TableEmpty from '../../components/TableEmpty';
import {
  BonusProductStatus,
  BonusProductStatusText,
  LIST_PAGE_COUNT,
} from '../../data/common';
import Swal from 'sweetalert2';
import { trim, map } from 'lodash';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import { useHistory } from 'react-router';
import bonusProductAPI from '../../middleware/bonusProductAPI';
import BonusProductTable from '../../components/BonusProduct/BonusProductTable';
import CardGiftcardRoundedIcon from '@material-ui/icons/CardGiftcardRounded';
import BonusProductReferralCodeModal from './BonusProductReferralCodeModal';

const BonusProductList = (props) => {
  const { match } = props;
  const history = useHistory();
  const [bonusProducts, setBonusProducts] = useState();
  const [bonusProductTotal, setBonusProductTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState({
    status: '',
    keyword: '',
    isPromo: false,
    isReferralCode: false,
  });
  const [page, setPage] = useState(1);
  const [referralCodeModalStatus, setReferralCodeModalStatus] = useState({
    bonusProductId: undefined,
    isOpen: false,
  });

  useEffect(() => {
    handleSearch();
  }, [page]);

  const handleChangeSearch = (e) => {
    const changeItem = e.target.name;
    const changeValue =
      changeItem !== 'isPromo' && changeItem !== 'isReferralCode'
        ? e.target.value
        : e.target.checked;

    setSearch({
      ...search,
      [changeItem]: changeValue,
    });
  };

  const handleSearch = () => {
    setIsLoading(true);

    const searchStatus = trim(search.status) === '' ? undefined : search.status;

    bonusProductAPI
      .getList(
        searchStatus,
        search.keyword,
        search.isPromo,
        search.isReferralCode,
        LIST_PAGE_COUNT,
        page,
      )
      .then(
        (res) => {
          setBonusProducts(res.productList);
          setBonusProductTotal(res.totalCount);
        },
        (error) => {
          alertError(error);
        },
      )
      .then(() => {
        setIsLoading(false);
      });
  };

  const handleCreate = () => {
    history.push('/BonusProduct/Create');
  };

  const handleSetPage = (page) => {
    setPage(page);
  };

  const toggleStatus = (bonusProduct) => {
    setIsLoading(true);

    const newStatus =
      bonusProduct.status === BonusProductStatus.ON_SALE
        ? BonusProductStatus.OFF_SALE
        : BonusProductStatus.ON_SALE;

    bonusProductAPI.setStatus(bonusProduct.id, newStatus).then(
      (res) => {
        handleSearch();
      },
      (error) => {
        alertError(error);
        setIsLoading(false);
      },
    );
  };

  const deleteBonusProduct = (id) => {
    Swal.fire({
      title: '確認刪除紅利商品',
      showCancelButton: true,
      confirmButtonText: '刪除',
      confirmButtonColor: 'red',
      cancelButtonText: '取消',
    }).then((res) => {
      if (res.isConfirmed) {
        setIsLoading(true);

        bonusProductAPI
          .delete(id)
          .then(
            () => {
              Swal.fire({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                title: '刪除成功',
                icon: 'success',
              });
              handleSearch();
            },
            (error) => {
              alertError(error);
            },
          )
          .then(() => {
            setIsLoading(false);
          });
      }
    });
  };

  const editBonusProduct = (id) => {
    history.push(`/BonusProduct/${id}`);
  };

  const openReferralCodeModal = (bonusProductId) => {
    setReferralCodeModalStatus({
      bonusProductId: bonusProductId,
      isOpen: true,
    });
  };

  const closeReferralCodeModal = () => {
    setReferralCodeModalStatus({
      bonusProductId: undefined,
      isOpen: false,
    });
  };

  return (
    <div className="popular pagefadeIn">
      <BreakCrumbs path={match.url}></BreakCrumbs>

      <Box component={Paper} square elevation={2} p={1} mt={3} mb={3}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center">
          <Grid item xs>
            <Typography variant="h4" color="textPrimary" gutterBottom>
              紅利商品列表
            </Typography>
          </Grid>

          <Grid item xs style={{ marignLeft: 'auto' }}>
            <div style={{ textAlign: 'right' }}>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<AddCircleOutlineOutlinedIcon />}
                disabled={isLoading}
                onClick={handleCreate}>
                建立商品
              </Button>
            </div>
          </Grid>
        </Grid>

        <Divider light />

        <Box textAlign="right" mt={2} mb={2}>
          <FormControlLabel
            control={
              <Checkbox
                name="isReferralCode"
                checked={search.isReferralCode}
                onChange={handleChangeSearch}
              />
            }
            label="有推薦碼"
            labelPlacement="end"
          />

          <FormControlLabel
            control={
              <Checkbox
                name="isPromo"
                checked={search.isPromo}
                onChange={handleChangeSearch}
              />
            }
            label="會員註冊禮"
            labelPlacement="end"
          />

          <FormControl
            size="small"
            variant="outlined"
            style={{ marginRight: '12px', minWidth: '150px' }}
            disabled={isLoading}>
            <InputLabel>狀態</InputLabel>
            <Select
              value={search.status}
              name="status"
              onChange={handleChangeSearch}
              label="狀態">
              <MenuItem value="">不設定</MenuItem>
              {map(BonusProductStatusText, (text, status) => {
                return (
                  <MenuItem value={status} key={status}>
                    {text}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <TextField
            label="商品名稱"
            type="search"
            name="keyword"
            variant="outlined"
            size="small"
            disabled={isLoading}
            value={search.keyword}
            onChange={handleChangeSearch}
            style={{ marginRight: '12px' }}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                if (page !== 1) {
                  setPage(1);
                } else {
                  handleSearch();
                }
              }
            }}
          />

          <Button
            variant="contained"
            color="primary"
            startIcon={<SearchIcon />}
            disabled={isLoading}
            onClick={() => {
              if (page !== 1) {
                setPage(1);
              } else {
                handleSearch();
              }
            }}>
            搜尋
          </Button>
        </Box>

        <Box textAlign="left">
          <div
            style={{
              fontSize: '0.8rem',
              fontWeight: '500',
              color: '#a98484',
              letterSpacing: '1px',
            }}>
            類型出現 <CardGiftcardRoundedIcon /> 代表會員註冊禮
          </div>
        </Box>

        {isLoading && <TableLoading />}

        {!isLoading && !bonusProducts && <TableEmpty message="無紅利商品" />}

        {!isLoading && bonusProducts && (
          <BonusProductTable
            bonusProducts={bonusProducts}
            isLoading={isLoading}
            bonusProductTotal={bonusProductTotal}
            page={page}
            setPage={handleSetPage}
            toggleStatus={toggleStatus}
            deleteBonusProduct={deleteBonusProduct}
            editBonusProduct={editBonusProduct}
            openReferralCodeModal={openReferralCodeModal}
          />
        )}
      </Box>

      {referralCodeModalStatus.isOpen && (
        <BonusProductReferralCodeModal
          close={closeReferralCodeModal}
          isOpen={referralCodeModalStatus.isOpen}
          bonusProductId={referralCodeModalStatus.bonusProductId}
        />
      )}
    </div>
  );
};

export default BonusProductList;
