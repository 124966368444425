import React, { useState } from 'react';
import {
  Button,
  TableCell,
  TableRow,
  Avatar,
  Switch,
  Chip,
  Badge,
} from '@material-ui/core';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  BonusProductStatus,
  BonusProductType,
  BonusProductTypeText,
} from '../../data/common';
import { formatNumberComma } from '../../Util/Util';
import moment from 'moment';
import MoneyOffRoundedIcon from '@material-ui/icons/MoneyOffRounded';
import NotInterestedRoundedIcon from '@material-ui/icons/NotInterestedRounded';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CardGiftcardRoundedIcon from '@material-ui/icons/CardGiftcardRounded';
import AssistantOutlinedIcon from '@material-ui/icons/AssistantOutlined';

const BonusProductTableRow = (props) => {
  const {
    bonusProduct,
    isLoading,
    toggleStatus,
    deleteBonusProduct,
    editBonusProduct,
    openReferralCodeModal,
  } = props;
  const [quantityStatus, setQuantityStatus] = useState('stock'); // stock 庫存數量, redeem 兌換數量, origin 設定數量

  const handleToggleStatus = () => {
    toggleStatus(bonusProduct);
  };

  const handleEdit = () => {
    editBonusProduct(bonusProduct.id);
  };

  const handleDelete = () => {
    deleteBonusProduct(bonusProduct.id);
  };

  const handleToggleQuantityStatus = () => {
    if (quantityStatus === 'stock') {
      setQuantityStatus('redeem');
    } else if (quantityStatus === 'redeem') {
      setQuantityStatus('origin');
    } else if (quantityStatus === 'origin') {
      setQuantityStatus('stock');
    }
  };

  const handleOpenReferralCode = () => {
    openReferralCodeModal(bonusProduct.id);
  };

  const quantity =
    quantityStatus === 'stock'
      ? parseInt(bonusProduct.quantity, 10) -
        parseInt(bonusProduct.redeemQuantity, 10)
      : quantityStatus === 'redeem'
      ? parseInt(bonusProduct.redeemQuantity, 10)
      : parseInt(bonusProduct.quantity, 10);

  const quantityText = {
    stock: '剩餘',
    redeem: '兌換',
    origin: '設定',
  }[quantityStatus];

  const quantityColor =
    quantity <= 0 && quantityStatus !== 'redeem' ? 'secondary' : 'primary';

  return (
    <TableRow>
      <TableCell component="th">
        <AvatarGroup max={3}>
          <Avatar
            variant="rounded"
            alt="封面"
            src={bonusProduct.mainImage.imageName}
          />
          {bonusProduct.subImage.map((subImage) => (
            <Avatar alt="圖片" key={subImage.id} src={subImage.imageName} />
          ))}
        </AvatarGroup>
      </TableCell>

      <TableCell component="th">{bonusProduct.name}</TableCell>

      <TableCell>
        <Chip
          color={
            bonusProduct.type === BonusProductType.DISCOUNT
              ? 'primary'
              : bonusProduct.type === BonusProductType.EXCHANGE
              ? 'secondary'
              : 'default'
          }
          size="small"
          label={BonusProductTypeText[bonusProduct.type]}
          icon={bonusProduct.isPromo ? <CardGiftcardRoundedIcon /> : undefined}
        />
      </TableCell>

      <TableCell>{formatNumberComma(bonusProduct.bonusPoint)}</TableCell>

      <TableCell>
        {bonusProduct.type === BonusProductType.DISCOUNT &&
          `$ ${formatNumberComma(bonusProduct.cash)}`}

        {bonusProduct.type !== BonusProductType.DISCOUNT && (
          <MoneyOffRoundedIcon />
        )}
      </TableCell>

      <TableCell>
        <Badge
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          showZero
          color={quantityColor}
          badgeContent={quantityText}>
          <div onClick={handleToggleQuantityStatus}>
            {quantityStatus === 'stock' && (
              <div className="bonus-product__quantity">{quantity}</div>
            )}

            {quantityStatus === 'redeem' && (
              <div className="bonus-product__quantity">{quantity}</div>
            )}

            {quantityStatus === 'origin' && (
              <div className="bonus-product__quantity">{quantity}</div>
            )}
          </div>
        </Badge>
      </TableCell>

      <TableCell>
        {bonusProduct.onSaleDate &&
          moment(bonusProduct.onSaleDate).format('YYYY-MM-DD')}
      </TableCell>

      <TableCell>
        {bonusProduct.offSaleDate &&
          moment(bonusProduct.offSaleDate).format('YYYY-MM-DD')}
      </TableCell>

      <TableCell>
        {moment(bonusProduct.createdDate).format('YYYY-MM-DD')}
      </TableCell>

      <TableCell>
        {bonusProduct.status !== BonusProductStatus.DELETE && (
          <Switch
            checked={bonusProduct.status === BonusProductStatus.ON_SALE}
            onChange={handleToggleStatus}
            color="primary"
          />
        )}

        {bonusProduct.status === BonusProductStatus.DELETE && (
          <NotInterestedRoundedIcon />
        )}
      </TableCell>

      <TableCell align="right">
        {bonusProduct.status !== BonusProductStatus.DELETE && (
          <Button
            variant="contained"
            color="default"
            size="small"
            startIcon={<AssistantOutlinedIcon />}
            disabled={isLoading}
            style={{ marginRight: '12px' }}
            onClick={handleOpenReferralCode}>
            推薦碼
          </Button>
        )}

        {bonusProduct.status !== BonusProductStatus.DELETE && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<EditOutlinedIcon />}
            disabled={isLoading}
            onClick={handleEdit}
            style={{ marginRight: '12px' }}>
            編輯
          </Button>
        )}

        {bonusProduct.status !== BonusProductStatus.DELETE && (
          <Button
            variant="contained"
            color="secondary"
            size="small"
            startIcon={<DeleteIcon />}
            disabled={isLoading}
            onClick={handleDelete}>
            刪除
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
};

export default BonusProductTableRow;
