import Swal from 'sweetalert2';
import _ from 'lodash';
import moment from 'moment';

/* eslint-disable no-undef */
function renderMathJax(dom) {
  let el = typeof dom === 'string' ? document.getElementById(dom) : dom;
  MathJax.Hub.Queue(['Typeset', MathJax.Hub, el]);
}

function signalR(callback) {
  let notificationHub = $.connection.notificationHub;
  $.connection.hub.url = 'https://www.liangshishu.link:34343/signalr';
  if (!notificationHub || !notificationHub.client) return;
  notificationHub.client.ReceiveMessage = function(message) {
    callback(message);
  };
  $.connection.hub.start(function() {
    notificationHub.server.joinRoom('Admin');
  });
}

const parseExamQuestionsToPieData = (examQuestions) => {
  let pieData = [];

  _.forEach(examQuestions, (examQuestion) => {
    parseKnowledges(pieData, examQuestion.Knowledges);
  });

  let total = _.sumBy(pieData, 'value');
  let onePercent = _.floor(_.floor(100 / total, 1) * 10);

  _.forEach(pieData, (item, index) => {
    pieData[index].value = item.value * onePercent;
  });

  const owedValue = 1000 - _.sumBy(pieData, 'value');
  const averageAdd = _.floor(owedValue / pieData.length);
  const lastNeedAdd = owedValue % pieData.length;

  pieData = _.sortBy(pieData, ['value', 'id']);

  _.each(pieData, (pie, index) => {
    const newValue =
      owedValue !== 0
        ? pieData[index].value + averageAdd + (lastNeedAdd > index ? 1 : 0)
        : pieData[index].value;

    pieData[index].value = newValue / 10;
  });

  return pieData;
};

const parseKnowledges = (pieData, knowledges) => {
  if (_.isArray(pieData) === false) {
    throw 'pieData need array';
  }

  _.forEach(knowledges, (knowledge, index) => {
    let knowledgeInPieDataIndex = _.findIndex(pieData, {
      id: _.toString(knowledge.Id),
    });

    if (knowledgeInPieDataIndex === -1) {
      pieData.push({
        id: _.toString(knowledge.Id),
        label: knowledge.Name,
        value: 1,
      });
    } else {
      pieData[knowledgeInPieDataIndex].value++;
    }

    if (
      _.isArray(knowledge.KnowledgeDtoChildren) &&
      knowledge.KnowledgeDtoChildren.length > 0
    ) {
      parseKnowledges(pieData, knowledge.KnowledgeDtoChildren);
    }
  });
};

/**
 * 依照輸入的產品內容為題庫或課程等，轉換成 ProductCard 使用的格式內容
 * 目前接收 題庫和課程 格式
 * @param {object} product
 */
const transformProductData = (product) => {
  let transformProduct = {
    type: '',
    id: -1,
    name: '',
    status: 0, // 0 刪除, 1上架, 2下架
    price: 0,
    discountPrice: 0,
    discountStartAt: '',
    discountEndAt: '',
    isDiscount: false,
    imgUrl: '',
    saleType: -1, // 試卷型態
    origin: {}, //原本資料型態
  };

  if (typeof product.HangoutId !== 'undefined') {
    /**
     * 課程 Hangout
     */
    transformProduct.type = 'hangout';
    transformProduct.id = product.HangoutId;
    transformProduct.name = product.Name;
    transformProduct.status =
      product.Status === 1 ? 1 : product.Status === 2 ? 2 : 0;
    transformProduct.price = product.Price;
    transformProduct.discountPrice =
      _.trim(product.DiscountPrice) === '' ? '' : product.DiscountPrice;
    transformProduct.discountStartAt =
      _.trim(product.DiscountStartAt) === ''
        ? ''
        : new Date(product.DiscountStartAt).toLocaleDateString();
    transformProduct.discountEndAt =
      _.trim(product.DiscountEndAt) === ''
        ? ''
        : new Date(product.DiscountEndAt).toLocaleDateString();
    transformProduct.imgUrl =
      _.trim(product.ImageUrl) === '' ? '' : product.ImageUrl;
  } else if (typeof product.QuestionBankId !== 'undefined') {
    /**
     * 題庫 questionBank
     */
    transformProduct.type = 'questionBank';
    transformProduct.id = product.QuestionBankId;
    transformProduct.name = product.Name;
    transformProduct.status =
      product.Status === 1 ? 1 : product.Status === 2 ? 2 : 0;
    transformProduct.price = product.Price;
    transformProduct.discountPrice =
      _.trim(product.DiscountPrice) === '' ? '' : product.DiscountPrice;
    transformProduct.discountStartAt =
      _.trim(product.DiscountStartAt) === ''
        ? ''
        : new Date(product.DiscountStartAt).toLocaleDateString();
    transformProduct.discountEndAt =
      _.trim(product.DiscountEndAt) === ''
        ? ''
        : new Date(product.DiscountEndAt).toLocaleDateString();
    transformProduct.imgUrl =
      _.trim(product.ImageUrl) === '' ? '' : product.ImageUrl;
    transformProduct.saleType = product.SaleType;
  } else if (typeof product.id !== 'undefined') {
    /**
     * 已處理過的直接回傳
     */
    return { ...product };
  }

  /**
   * 將原本結構放在 origin ， 供需要時可拿出來使用
   */
  transformProduct.origin = product;

  /**
   * 計算是否在特價中
   */
  if (
    transformProduct.discountStartAt !== '' &&
    transformProduct.discountEndAt !== ''
  ) {
    const nowTime = _.parseInt(new Date().getTime());
    const discountStartTime = _.parseInt(
      new Date(transformProduct.discountStartAt).getTime(),
    );
    const discountEndTime = _.parseInt(
      new Date(transformProduct.discountEndAt).getTime(),
    );

    if (discountStartTime <= nowTime && discountEndTime >= nowTime) {
      transformProduct.isDiscount = true;
    }
  }

  return transformProduct;
};

const transformProductsData = (products) => {
  if (_.isArray(products) === false) {
    return [];
  }

  return _.map(products, (product) => transformProductData(product));
};

const alertError = (error, errorTitle, onClose) => {
  Swal.fire({
    title: errorTitle ? errorTitle : '發生錯誤',
    html: _.isArray(error.messages) ? _.join(error.messages, '<br />') : error,
    icon: 'error',
    didClose: onClose ? onClose : undefined,
  });
};

function intToChinese(num, digits, units, minus, isCheque) {
  let str = '';
  let n = Math.floor(Math.abs(num));
  if (n < 1) return (num < 0 ? minus : '') + digits[0];

  let uc = units.slice();
  while (n > 0) {
    let u = uc.shift();
    let d = n % 10;
    str = digits[d] + u + str; //((d > 0) ? u : '') + str;

    n = Math.floor(n / 10);
  }

  const smallUnit = units[1] + units[2] + units[3];
  const bigUnit = units[4] + units[8] + units[12] + units[16] + units[20];
  const zero = digits[0];

  str = str
    .replace(new RegExp('(' + zero + ')[' + smallUnit + ']', 'g'), '$1') //零千,零百,零十 keeps 零
    .replace(
      new RegExp(
        '([' + bigUnit + '])[^' + smallUnit + ']+([' + bigUnit + '])',
        'g',
      ),
      '$1' + zero,
    ) //大數中間沒細數，補零
    .replace(
      new RegExp('([' + smallUnit + '])' + zero + '+([' + bigUnit + '])', 'g'),
      '$1$2' + zero,
    )
    .replace(new RegExp('(' + digits[0] + ')+', 'g'), '$1') //group 零
    .replace(new RegExp(zero + '+$'), ''); //tail zero remove

  if (isCheque != true) {
    //check writing reserve the first "一"
    str = str.replace(new RegExp('^' + digits[1] + units[1]), units[1]); //^一十 == 十
  }

  return (num < 0 ? minus : '') + str;
}

const chineseWordLables = {
  digits: ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'],
  units: [
    '',
    '十',
    '百',
    '千',
    '萬',
    '十',
    '百',
    '千',
    '億',
    '十',
    '百',
    '千',
    '兆',
    '十',
    '百',
    '千',
    '京',
    '十',
    '百',
    '千',
    '垓',
  ],
  ordinal: '第',
  point: '點',
  minus: '負',
};

function numberToChineseWords(number) {
  return intToChinese(
    number,
    chineseWordLables.digits,
    chineseWordLables.units,
    chineseWordLables.minus,
  );
}

const seconds2time = (seconds) => {
  if (!seconds) {
    return 0;
  }

  if (String(seconds).indexOf(':') !== -1) {
    return seconds;
  }

  var hours = Math.floor(seconds / 3600);
  var minutes = Math.floor((seconds - hours * 3600) / 60);
  var minSeconds = seconds - hours * 3600 - minutes * 60;
  var time = '';

  if (hours != 0) {
    time = hours + ':';
  }
  if (minutes != 0 || time !== '') {
    minutes = minutes < 10 && time !== '' ? '0' + minutes : String(minutes);
    time += minutes + ':';
  }
  if (time === '') {
    time = minSeconds;
  } else {
    time += minSeconds < 10 ? '0' + minSeconds : String(minSeconds);
  }
  return time;
};

const time2seconds = (time) => {
  const secondsTestReg = /^\d+$/gm;
  const timeTestReg = /^(\d{1,2}:)?(\d{1,2}:)?\d{1,2}$/gm;

  if (secondsTestReg.test(time)) {
    return time;
  }

  if (!timeTestReg.test(time)) {
    return false;
  }

  const splitTimes = String(time).split(':');
  let hours = 0;
  let minutes = 0;
  let minSeconds = 0;
  let seconds = 0;

  if (splitTimes.length === 3) {
    hours = parseInt(splitTimes[0], 10);
    minutes = parseInt(splitTimes[1], 10);
    minSeconds = parseInt(splitTimes[2], 10);
  } else if (splitTimes.length === 2) {
    minutes = parseInt(splitTimes[0], 10);
    minSeconds = parseInt(splitTimes[1], 10);
  } else if (splitTimes.length === 1) {
    minSeconds = parseInt(splitTimes[0], 10);
  }

  if (minutes >= 60 || minSeconds >= 60) {
    return false;
  }

  seconds = hours * 3600 + minutes * 60 + minSeconds;

  return parseInt(seconds, 10);
};

const formatNumberComma = (number) => {
  return parseInt(number, 10).toLocaleString();
};

const dateFormat = (date) => {
  return date instanceof Date ? moment(date).format('YYYY-MM-DD') : null;
};

const isOnlineEnv = () => {
  return process.env.REACT_APP_WEBSITE_ENV === 'online';
};

const isOnlineTestEnv = () => {
  return process.env.REACT_APP_WEBSITE_ENV === 'onlineTest';
};

const isLocalEnv = () => {
  return process.env.REACT_APP_WEBSITE_ENV === 'local';
};

const getEnvName = () => {
  if (isOnlineEnv()) {
    return '正式站';
  }

  if (isOnlineTestEnv()) {
    return '測試站';
  }

  if (isLocalEnv()) {
    return '開發站';
  }
};

export {
  renderMathJax,
  signalR,
  parseExamQuestionsToPieData,
  parseKnowledges,
  transformProductData,
  transformProductsData,
  alertError,
  numberToChineseWords,
  seconds2time,
  time2seconds,
  formatNumberComma,
  dateFormat,
  isOnlineEnv,
  isOnlineTestEnv,
  isLocalEnv,
  getEnvName,
};
