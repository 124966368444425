const Map = {
  Area: {
    1: '台灣',
    2: '中國',
  },
  SourceUrlType: {
    handoutNode: 1,
    video: 2,
    videoNode: 3,
  },
  MaterialTypes: {
    video: 1,
    questionBank: 2,
    homeWork: 3,
    pdf: 4,
    assignment: 5,
  },
  MaterialTypeNumbers: {
    1: {
      type: 'video',
      name: '影片節點',
    },
    2: {
      type: 'questionBank',
      name: '測驗',
    },
    3: {
      type: 'homeWork',
      name: '作業',
    },
    4: {
      type: 'pdf',
      name: 'PDF',
    },
    5: {
      type: 'assignment',
      name: '作業',
    },
  },
  SubmissionTypes: {
    1: {
      type: 'video',
      name: '影片',
    },
    2: {
      type: 'word',
      name: 'WORD',
    },
    3: {
      type: 'pdf',
      name: 'PDF',
    },
  },
};

export default Map;
