import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
} from '@material-ui/core';
import { LIST_PAGE_COUNT } from '../../data/common';
import TablePaginationActions from '../TablePaginationActions';
import BonusProductTableRow from './BonusProductTableRow';
import { map } from 'lodash';

const BonusProductTable = (props) => {
  const {
    bonusProducts,
    bonusProductTotal,
    page,
    setPage,
    isLoading,
    toggleStatus,
    deleteBonusProduct,
    editBonusProduct,
    openReferralCodeModal,
  } = props;

  return (
    <TableContainer component={Box}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell>圖片</TableCell>
            <TableCell>名稱</TableCell>
            <TableCell>類型</TableCell>
            <TableCell>紅利點數</TableCell>
            <TableCell>現金</TableCell>
            <TableCell>數量</TableCell>
            <TableCell>上架時間</TableCell>
            <TableCell>下架時間</TableCell>
            <TableCell>建立時間</TableCell>
            <TableCell>狀態</TableCell>
            <TableCell align="right">功能</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {map(bonusProducts, (bonusProduct) => (
            <BonusProductTableRow
              key={bonusProduct.id}
              bonusProduct={bonusProduct}
              isLoading={isLoading}
              toggleStatus={toggleStatus}
              deleteBonusProduct={deleteBonusProduct}
              editBonusProduct={editBonusProduct}
              openReferralCodeModal={openReferralCodeModal}
            />
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[LIST_PAGE_COUNT]}
              colSpan={11}
              count={bonusProductTotal}
              rowsPerPage={LIST_PAGE_COUNT}
              page={page - 1}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onChangePage={(event, currentPage) => {
                setPage(currentPage + 1);
              }}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default BonusProductTable;
