import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import SecretList from './SecretList';
import SecretType from './SecretType';
import EditSecret from './EditSecret';
import AddSecret from './AddSecret';
import Page404 from '../../components/Page404';

class Secret extends Component {
  render() {
    return (
      <Switch>
        <Route exact path={`${this.props.match.url}`} component={SecretList} />
        <Route
          exact
          path={`${this.props.match.url}/SecretList/Trash`}
          component={SecretList}
        />
        <Route
          exact
          path={`${this.props.match.url}/SecretList`}
          component={SecretList}
        />
        <Route
          exact
          path={`${this.props.match.url}/SecretType`}
          component={SecretType}
        />
        <Route
          exact
          path={`${this.props.match.url}/AddSecret`}
          component={AddSecret}
        />
        <Route
          exact
          path={`${this.props.match.url}/EditSecret/:HangoutId`}
          component={EditSecret}
        />
        <Route component={Page404} />
      </Switch>
    );
  }
}

export default Secret;
