import instance from './API';

const basePath = '/Teacher';

const teacherAPI = {
  getList: (params) => {
    const urlQuery = params ? '?' + new URLSearchParams(params).toString() : '';

    return instance.get(basePath + urlQuery);
  },
  get: (teacherId) => {
    return instance.get(basePath + '/' + teacherId);
  },
  add: (params) => {
    return instance.post(basePath, params);
  },
  edit: (params) => {
    return instance.put(basePath, params);
  },
  delete: (teacherId) => {
    return instance.delete(basePath + '?teacherId=' + teacherId);
  },
};

export default teacherAPI;
