import React, { useState } from 'react';
import PropTypes from 'prop-types';
//import DatePicker from 'react-datepicker';
import DateTimePicker from 'react-datetime';
import moment from 'moment';
import 'moment/locale/zh-tw';
import '../../style/components/SaleExam/add-new-type.scss';
const AddNewPromo = ({ title, isVisible, closeAddBlock, AddNewPromoFunc }) => {
  const [startAt, setStartAt] = useState(moment());
  const [endAt, setEndAt] = useState(moment());
  const [inputValue, setInputValue] = useState('');

  const handleCloseAddBlock = () => {
    setInputValue('');
    setStartAt(moment());
    setEndAt(moment());
    closeAddBlock();
  };

  const handleAddNewPromo = () => {
    if (inputValue.length === 0) {
      alert('活動不可無標題');
      return;
    }

    if (Date.parse(startAt).valueOf() >= Date.parse(endAt).valueOf()) {
      alert('開始時間不能大於等於結束時間！');
      return;
    }
    AddNewPromoFunc({
      Usage: inputValue,
      StartAt: startAt.format('YYYY/M/D HH:mm'),
      EndAt: endAt.format('YYYY/M/D HH:mm'),
    });
    setInputValue('');
    setStartAt(moment());
    setEndAt(moment());

    closeAddBlock();
  };

  const handleInputValue = (e) => {
    setInputValue(e.target.value);
  };

  const handleStartAtValue = (e) => {
    if (Date.parse(e).valueOf() > Date.parse(endAt).valueOf()) {
      alert('開始時間不能大於等於結束時間！');
      return;
    }
    setStartAt(e);
  };

  const handleEndAtValue = (e) => {
    if (Date.parse(startAt).valueOf() > Date.parse(e).valueOf()) {
      alert('結束時間不能小於等於開始時間！');
      return;
    }
    setEndAt(e);
  };
  moment.locale('zh-tw');

  return (
    <div
      className="add-new-exam-type fadeIn"
      style={{ display: isVisible ? 'flex' : 'none' }}>
      <div className="add-block">
        <div className="cross" onClick={handleCloseAddBlock}>
          X
        </div>
        <div className="title">{title}</div>
        <div className="title">(只有在日期區間內才可兌換)</div>
        <div className="body" style={{ display: 'block' }}>
          活動名稱：
          <br />
          <input type="text" value={inputValue} onChange={handleInputValue} />
          <br />
          開始日期：
          <DateTimePicker
            inputProps={{ readOnly: true }}
            value={startAt}
            onChange={(date) => {
              handleStartAtValue(date);
            }}
          />
          <div className="line"></div>
          結束日期：
          <DateTimePicker
            inputProps={{ readOnly: true }}
            value={endAt}
            onChange={(date) => {
              handleEndAtValue(date);
            }}
          />
          <br />
          <div style={{ textAlign: 'center' }}>
            <button className="btn add-save" onClick={handleAddNewPromo}>
              儲存
            </button>
            <button className="btn add-cancel" onClick={handleCloseAddBlock}>
              取消
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

AddNewPromo.propTypes = {
  title: PropTypes.string,
  isVisible: PropTypes.bool,
  AddNewPromoFunc: PropTypes.func,
  closeAddBlock: PropTypes.func,
};

export default AddNewPromo;
