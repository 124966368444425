import React from 'react';
import { NavLink } from 'react-router-dom';

import '../style/components/sidebar.scss';
import pathName from '../data/pathName.json';
import restaurantPathName from '../data/restaurantPathName.json';
import { useSelector } from 'react-redux';

const Slidebar = () => {
  const loginState = useSelector((state) => state.loginState);
  const closeMenu = (item) => {
    document.getElementById('toggle-menu').checked = false;
  };

  const toggleThisMenu = (select) => {
    let dom = document.querySelector(select);
    let numberOfChildren = dom.children.length;
    let heightOfChildren = dom.children[0].clientHeight;
    if (dom.style.height === '' || dom.style.height === '0px') {
      dom.style.height = numberOfChildren * heightOfChildren + 'px';
    } else {
      dom.style.height = '0px';
    }
  };

  const drawTab = (item) => (
    <li key={item.id}>
      <NavLink
        to={`/${item.link}`}
        onClick={closeMenu}
        exact
        activeClassName="active">
        {item.name}
      </NavLink>
    </li>
  );

  const drawsidebar = (pathName) => {
    return pathName.map((item) => {
      if ('children' in item) {
        return (
          <li key={item.id}>
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                toggleThisMenu(`.child-block-${item.link}`);
              }}>
              {item.name} <i className="fas fa-angle-down"></i>
            </a>
            <ul className={`child-block-${item.link}`}>
              {drawsidebar(item.children)}
            </ul>
          </li>
        );
      } else {
        return drawTab(item);
      }
    });
  };

  const isRestaurant = String(loginState.authority) === '65536';

  return (
    <div className="slidebar">
      <label htmlFor={'toggle-menu'} className="closed"></label>
      <ul className="nav-bar">
        <li className="ipad-home">
          <NavLink exact to="/" onClick={closeMenu} activeClassName="active">
            首頁
          </NavLink>
        </li>

        {isRestaurant && drawsidebar(restaurantPathName)}

        {!isRestaurant && drawsidebar(pathName)}
      </ul>
    </div>
  );
};

export default Slidebar;
