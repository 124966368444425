import React from 'react';
import _ from 'lodash';
import {
  Button,
  TableCell,
  TableRow,
  Avatar,
  Switch,
  FormControlLabel,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import FaceRoundedIcon from '@material-ui/icons/FaceRounded';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const ActionButton = withStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(1),
  },
}))(Button);

const TeacherTableRow = (props) => {
  const { teacher, toggleStatus, isProcessing, deleteTeacher } = props;
  const history = useHistory();

  const handleDeleteTeacher = () => {
    Swal.fire({
      title: '確定要刪除嗎？',
      text: '如有課程設定該師資，將清空課程師資設定',
      icon: 'question',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: '確定',
      cancelButtonText: '取消',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteTeacher(teacher.teacherId);
      }
    });
  };

  const handleEditTeacher = () => {
    history.push('/Teacher/Edit/' + teacher.teacherId);

    return false;
  };

  return (
    <StyledTableRow>
      <TableCell component="th">{teacher.teacherId}</TableCell>

      <TableCell>
        {teacher.imageName ? (
          <Avatar alt={teacher.name} src={teacher.imageUrl} />
        ) : (
          <Avatar>
            <FaceRoundedIcon />
          </Avatar>
        )}
      </TableCell>

      <TableCell>{teacher.name}</TableCell>

      <TableCell>
        <FormControlLabel
          checked={teacher.status === 1}
          control={<Switch color="primary" />}
          label={teacher.status === 1 ? '上線' : '下線'}
          labelPlacement="end"
          disabled={isProcessing}
          onChange={(e) => {
            toggleStatus(teacher, e.target.checked);
          }}
        />
      </TableCell>

      <TableCell align="right">
        <ActionButton
          variant="contained"
          color="primary"
          size="small"
          startIcon={<EditIcon />}
          disabled={isProcessing}
          onClick={handleEditTeacher}>
          編輯
        </ActionButton>
        <ActionButton
          variant="contained"
          color="secondary"
          size="small"
          startIcon={<DeleteIcon />}
          disabled={isProcessing}
          onClick={handleDeleteTeacher}>
          刪除
        </ActionButton>
      </TableCell>
    </StyledTableRow>
  );
};

export default TeacherTableRow;
